div.filters {
  background: #ffffff;
  color: #1D1D1D;
  font-weight: 600;
  font-size: 14px;

  span {
    border: 1px solid #797979;
    font-weight: 600;
    cursor: pointer;
  }

  span.selected {
    color: #ffffff;
    background: #797979;
  }
}

table.table {
  font-size: 14px;

  tr {
    th {
      font-weight: 600;
      background: #E4FD3D;
      border-color: #f0f0f0;
    }

    td {
      span.rounded-pill {
        font-weight: 600;
      }

      span.rounded-pill.active {
        background: #E4FD3D;
      }

      span.rounded-pill.inactive {
        background: #E8E8E8;
      }
    }
  }
}

.btn-action{
  cursor: pointer;
  text-decoration: underline;
}
