@import "../../styles/shared.scss";

#login-container{
  width: 100%;
  margin: 5% auto;

  @media (min-width: $breakpoint-tablet) {
    width: 450px;
  }

  label{
    margin-bottom: 0.5em;
  }
}