div.buttons-container {
  background: #ffffff;
  color: #1D1D1D;
  font-weight: 700;
  font-size: 14px;

  span {
    border: 1px solid #1D1D1D;
    font-weight: 600;
    cursor: pointer;
  }

  span.selected {
    color: #ffffff;
    background: #1D1D1D;
  }
}
