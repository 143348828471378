@import "../src/styles/shared.scss";

* {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

body {

}

html, body, #root, .App{
  width: 100%;
}

.App {
  display: flex;
  flex-direction: row;
}

#menu {
  display: flex;
  flex-direction: column;
  width: 355px;
  background-color: $color-gray-background;
  padding: 24px;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  overflow: scroll;
  height: 100vh;

  div#menu-container {
  }
}

#app-content-container {
  width: calc(100% - 355px);
  padding: 24px 48px;
  background: #fbfbfb;
  height: 100vh;
  overflow: scroll;

  #app-content {
  }
}

.success{
  font-size: 14px;
  line-height: 1.2em;
  color: green;
}

.error{
  font-size: 14px;
  line-height: 1.2em;
  color: red;
}

.pointer{
  cursor: pointer !important;
}

.btn-green{
  border: 0 !important;
  background-color: $color-green !important;
  color: #333 !important;
  font-weight: 600 !important;
  width: auto !important;
}

