@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@500;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;500;700&display=swap');

@import "bootstrap-icons/font/bootstrap-icons.scss";

@import "breakpoint";
@import "colors";
@import "reset";
@import "typography";

