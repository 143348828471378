@import "../../styles/shared.scss";

button{
  width: 100%;
  appearance: none;
  height: 3.5rem;
  line-height:1.62rem;
  background-color: $color-black;
  border: 1px solid $color-black;
  color: $color-white;
  font-family: $barlow-condensed;
  text-transform: uppercase;
  font-size: 1.37rem;
  cursor: pointer;
  padding: 0;
  font-weight: 600;
  margin: 0.5em 0 !important;

  &.primary{

  }
}

.button{}