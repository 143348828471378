div.search-container {
  background: #ffffff;
  max-width: 300px;
  font-size: 14px;
  color: #1D1D1D;

  i {
    font-size: 20px;
  }

  input {
    color: #1D1D1D;
    font-weight: 600;
  }
}
