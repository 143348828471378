$barlow-condensed: 'Barlow Condensed', sans-serif;
$barlow: 'Barlow', sans-serif;
$roboto: 'Roboto', sans-serif;
$roboto-condensed: 'Roboto Condensed', sans-serif;

h1 {
  font-family: $roboto;
  font-size: 1.8em !important;
  font-weight: 800 !important;
}
